






































































































































































import CreateUpdateComponent from "@/popupcomponents/createupdateuser/CreateUpdateComponent";
export default CreateUpdateComponent;
