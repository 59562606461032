import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import { getRoles, addUser, getAccounts, editUser, getProgramByAccount, getSiteByProgramId } from "@/services/create-user/createUserService";
import { ICreateUser, IRole, IAccount, IProgram, ISite } from '@/Model/programModel';
import APP_UTILITIES from "@/utilities/commonFunctions";
import APP_CONST from '@/constants/AppConst';
import ProgressButton from '@/commoncomponents/progressButton/progressButton.vue';
import DatepickerComponent from '@/ui-components/datepicker/DatepickerComponent.vue';
@Component({
    components: {
        'progress-button': ProgressButton,
        'datepicker': DatepickerComponent,
    }
})
export default class CreateUpdateComponent extends Vue {
    
    private objScreenText: ScreenText = new ScreenText();
    createUser = JSON.parse(JSON.stringify(APP_CONST.USER_CREATION));
    validatedFields = JSON.parse(JSON.stringify(APP_CONST.VALIDATE_CREATE_USER_FORM));
    isValidEmail: boolean | null = null;
    duplicateUser: boolean = false;
    public openDropStatus: boolean = false;
    public openDropAccountStatus: boolean = false;
    public openDropProgramStatus: boolean = false;
    public openDropSiteStatus: boolean = false;
    public openDropUserStatus: boolean = false;
    isPhoneLengthValid: boolean | null = null;
    public roles: IRole[] = [] as IRole[];
    public accounts: IAccount[] = [] as IAccount[];
    public programs: IProgram[] = [] as IProgram[];   
    public sites: ISite[] = [] as ISite[];
    public StatusValues=[
        { id: 1, name: "Active" },
        { id: 2, name: "Inactive" },
    ];
    errorMsg: string = '';
    public allFieldRequired : boolean = false;
    public isApiError :boolean =APP_CONST.FALSE;
    public apiErrorMessage : string ='';
    public status:number=0;
    localConstant = APP_CONST;
    public apiValidations : any = {
        firstName : {
            isRequired : false,
            errorMSG : ''
        },
        lastName : {
            isRequired : false,
            errorMSG : ''
        }
    };
    public inputValue: string='';
    isPersonalInvitationNote : boolean | null = null;    
    public apiResponseCode: number = 0;
    public isValid: boolean = true;
    public isMobileView:boolean=false;
    public disableStatus:boolean=false;
    public todayDate:string = APP_CONST.BLANK

    @Prop()
    requiredIds!: { roleId: 0 }

    @Prop({type: Object, default: JSON.parse(JSON.stringify(APP_CONST.USER_CREATION)) as ICreateUser})
    userDetail!: ICreateUser;


    @Prop()
    tabSelected!:string;

    @Prop()
    user!: any;

    @Emit('checkAddStatus') updateParentList(status:any) { }

    beforeMount() {
        this.getRoles();
    }

    isMobile(){
        this.isMobileView =APP_UTILITIES.isMobile()
    }

    mounted() {
        this.isMobileView =APP_UTILITIES.isMobile()
        window.addEventListener("resize", APP_UTILITIES.debounce(this.isMobile));
        let date = new Date();
        this.todayDate = ("0" + (date.getUTCMonth()+1)).slice(-2) + "/" +("0" + (date.getUTCDate())).slice(-2) + "/" + date.getUTCFullYear()
    }

    @Watch('userDetail', {deep: true, immediate: true})
    details(newData: any) {this.createUser = JSON.parse(JSON.stringify(newData));
        if(this.createUser.status==undefined){
            this.createUser.status= 0;
        }
        if(this.createUser.inactiveDate == undefined){
            this.createUser.inactiveDate= new Date();
        }
        else{
            this.createUser.inactiveDate=new Date(this.createUser.inactiveDate)
        }
        this.createUser.inactiveDateDisable = this.createUser.status != 2 ? APP_CONST.TRUE : APP_CONST.FALSE;
        if(this.createUser.phoneNumber) this.checkPhoneFormat(null);
        if(this.createUser.userId && this.createUser.userId > APP_CONST.ZERO) {
            const accountId = this.createUser.userRoles[APP_CONST.ZERO].accountId;
            const programId = this.createUser.userRoles[APP_CONST.ZERO].programId;
            accountId && this.getAccountsDetails();
            accountId && programId && this.getPrograms(accountId);
            programId && this.getSite(programId);
        }
        let loggedInUserId:any= APP_UTILITIES.getCookie('user_id');
        if(Number(loggedInUserId)==this.createUser.id){
            this.disableStatus=true;
        }
    }

    @Watch('requiredIds',{deep:true})
    checkForRoleIdUpdate(val:any){
        let roleId:number = this.requiredIds.roleId;
        this.getRoles();
        this.selectRole({Id: 0, Name: 'Select System Role'})
        if(roleId==2){
            this.getAccountsDetails();
        }
    }
  
    public getScreenText(key: string): string {
        return this.objScreenText.getScreenText(key);
    }

    getRoles() {
        const roleId = this.requiredIds.roleId;
        getRoles(roleId).then((res: any) => {
            if (res.status === APP_CONST.RESPONSE_200) {
                const data = JSON.parse(res.data);
                this.roles = data as IRole[];
            }
        })
    }

    getAccountsDetails(){
        getAccounts().then((res:any) => {
            if(res.status === APP_CONST.RESPONSE_200){
                const data = res.data;
                this.accounts = data as IAccount[];
                const accountId = this.createUser.userRoles[APP_CONST.ZERO].accountId;
                if(this.createUser.userId && this.createUser.userId > APP_CONST.ZERO && accountId) {
                    const index = this.accounts.findIndex((el: any) => el.id === accountId);
                    this.createUser.accountName = (index > APP_CONST.MINUS_ONE) ? this.accounts[index].name : 'Select Account';
                }
            }
        })
    }

    getPrograms(accountId: number){
        getProgramByAccount(accountId).then((res:any) => {
            if(res.status === APP_CONST.RESPONSE_200){
                const data = res.data;
                this.programs = data as IProgram[];
                const programId = this.createUser.userRoles[APP_CONST.ZERO].programId;
                if(this.createUser.userId && this.createUser.userId > APP_CONST.ZERO && programId) {
                    const index = this.programs.findIndex((el: any) => el.id === programId);
                    this.createUser.programName = (index > APP_CONST.MINUS_ONE) ? this.programs[index].name : 'Select Account';
                }
            }
        })
    }

    getSite(programId: number){
        getSiteByProgramId(programId).then((res:any) => {
            if(res.status === APP_CONST.RESPONSE_200){
                const data = res.data;
                this.sites = data;
                const siteId = this.createUser.userRoles[APP_CONST.ZERO].siteId;
                if(this.createUser.userId && this.createUser.userId > APP_CONST.ZERO && siteId) {
                    const index = this.sites.findIndex((el: any) => el.siteId === siteId);
                    this.createUser.siteName = (index > APP_CONST.MINUS_ONE) ? this.sites[index].siteName : 'Select Site/Session';
                }
            }
        })
    }

    selectRole(role: {Id: number, Name: string}) {
        this.validatedFields.roleId.error = false;
        this.createUser.userRoles[0].roleId = role.Id; 
        this.createUser.roleName = role.Name; 
        this.openDropStatus = false;
        this.selectAccount({id: 0, name: 'Select Account'})
    }

    selectStatus(obj:any ){
        if(this.createUser.status){
            this.createUser.status = obj.id;
            if(this.createUser.status == 2) {
                this.createUser.inactiveDateDisable = APP_CONST.FALSE;
            } else {
                this.createUser.inactiveDateDisable  = APP_CONST.TRUE;
            }
        }
        
        this.openDropUserStatus=false;
       
       
    }

    selectAccount(account: {id: number, name: string}) {
        this.validatedFields.accountId.error = false;
        this.createUser.userRoles[0].accountId = account.id; 
        this.createUser.accountName = account.name; 
        if(account.id > APP_CONST.ZERO) {
            this.getPrograms(account.id);
            this.selectProgram({id: 0, name: 'Select Program'}); 
            this.programs = [];   
        } else {
            this.selectProgram({id: 0, name: 'Select Program'}); 
            this.programs = [];       
        }
        this.openDropAccountStatus = false;
    }

    clearSearchField(){
        this.inputValue=""
    }

    accountsVisible (item:string) {
        let currentAccountName = item.toLowerCase()
        let currentInput = this.inputValue.toLowerCase()
        return currentAccountName.includes(currentInput)
    }

    selectProgram(program: {id: number, name: string}) {
        this.validatedFields.programId.error = false;
        this.createUser.userRoles[0].programId = program.id; 
        this.createUser.programName = program.name; 
        if(program.id > APP_CONST.ZERO) {
            this.getSite(program.id);
            this.selectSite({siteId: 0, siteName: 'Select Site/Session'}); 
            this.sites = [];    
        } else {
            this.selectSite({siteId: 0, siteName: 'Select Site/Session'}); 
            this.sites = [];       
        }
        this.openDropProgramStatus = false;
    }

    selectSite(site: {siteId: number, siteName: string}) {
        this.validatedFields.siteId.error = false;
        this.createUser.userRoles[0].siteId = site.siteId; 
        this.createUser.siteName = site.siteName; 
        this.openDropSiteStatus = false;
    }

    openDropdown(type:string,close?: boolean) {
        if(type==''){
            this.openDropAccountStatus = false;
            this.openDropProgramStatus = false;
            this.openDropSiteStatus = false;
            this.openDropUserStatus=false;
            this.openDropStatus = this.openDropStatus ? APP_CONST.FALSE : (close ? APP_CONST.FALSE : APP_CONST.TRUE);

        }
        else if(type=='account-open'){
            this.openDropStatus = false;
            this.openDropAccountStatus = this.openDropAccountStatus ? APP_CONST.FALSE : (close ? APP_CONST.FALSE : APP_CONST.TRUE);
            this.openDropProgramStatus = false;
            this.openDropSiteStatus = false;
            this.openDropUserStatus=false;
        } else if(type=='program-open') {
            this.openDropStatus = false;
            this.openDropUserStatus=false;
            this.openDropAccountStatus = false;
            this.openDropProgramStatus = this.openDropProgramStatus ? APP_CONST.FALSE : (close ? APP_CONST.FALSE : APP_CONST.TRUE);
            this.openDropSiteStatus = false;
        } else if(type=='site-open') {
            this.openDropStatus = false;
            this.openDropUserStatus=false;
            this.openDropAccountStatus = false;
            this.openDropProgramStatus = false;
            this.openDropSiteStatus = this.openDropSiteStatus ? APP_CONST.FALSE : (close ? APP_CONST.FALSE : APP_CONST.TRUE);
        }
        else if(type=='status-open') {
            this.openDropStatus = false;
            this.openDropUserStatus=this.openDropUserStatus ? APP_CONST.FALSE : (close ? APP_CONST.FALSE : APP_CONST.TRUE);
            this.openDropAccountStatus = false;
            this.openDropProgramStatus = false;
            this.openDropSiteStatus = false;
        }
        this.clearSearchField();
    }

   
    

    public checkPhoneFormat(event: any) {
        if (event && APP_UTILITIES.isModifierKey(event)) { return; }
        let input: any = this.createUser.phoneNumber && this.createUser.phoneNumber.replace(/\D/g, '').substring(0, 10); 
        const zip = input && input.substring(0, 3);
        const middle = input && input.substring(3, 6);
        const last = input && input.substring(6, 10);
        this.createUser.phoneNumber = `(${zip}) ${middle}-${last}`;
    };

    public enforceFormat(event: any) {
        if (!APP_UTILITIES.isNumericInput(event) && !APP_UTILITIES.isModifierKey(event)) {
            event.preventDefault();
        }
    };

    doUser(performUser: any) {
        this.apiErrorHandling();
        this.duplicateUser = APP_CONST.FALSE;
        this.isApiError=APP_CONST.FALSE;
        this.isPhoneLengthValid = null;
        this.isPersonalInvitationNote=null;
        const user = { ...this.createUser };
        const ValidateObj = JSON.parse(JSON.stringify(APP_CONST.VALIDATE_CREATE_USER_FORM));
        const checkValidation = APP_UTILITIES.requiredFields(user, ValidateObj, true);
        this.validatedFields = JSON.parse(JSON.stringify({ ...checkValidation.validateData }));
        if (checkValidation.requiredFieldsFilled) {
            this.allFieldRequired = checkValidation.requiredFieldsFilled
            return;
        }

        if(checkValidation.isFormInvalid) {
            return;
        }

            const validDomains = this.requiredIds.roleId === APP_CONST.ONE ? APP_CONST.VALID_EMAILS : APP_CONST.NON_BX_VALID_EMAILS;
            this.isValidEmail = APP_UTILITIES.validateEmail(user.email, validDomains, user.userRoles[APP_CONST.ZERO].roleId);
            if(!this.isValidEmail) {
                this.allFieldRequired = APP_CONST.FALSE;
                return
            }
            this.allFieldRequired = APP_CONST.FALSE;

        user.phoneNumber = APP_UTILITIES.removeSpecialCharacters(user.phoneNumber);
        if(user.phoneNumber && (user.phoneNumber.length !== 10)) {
            this.isPhoneLengthValid = APP_CONST.FALSE
            return
        } else {
            this.isPhoneLengthValid = APP_CONST.TRUE;
        }
        if(user.personalInvitationNote && user.personalInvitationNote.length>255){
            this.isPersonalInvitationNote= APP_CONST.FALSE;
            return
        }
        if(((!this.validatedFields.lastName['alphaCharError']) && (!this.validatedFields.lastName['minimumCharError'])) && (!this.validatedFields.firstName['alphaCharError']) && (!this.validatedFields.firstName['minimumCharError']))
        {
        delete user.inactiveDateDisable;
        performUser(user);
        }
    }

    public apiErrorHandling(){
        this.apiValidations = {
            firstName : {
                isRequired : false,
                errorMSG : ''
            },
            lastName : {
                isRequired : false,
                errorMSG : ''
            }
        };
    }

    makeUser(createUser: ICreateUser) {
        this.isValid = false;
        this.apiResponseCode = 0;
        
        addUser(createUser).then((res: any) => {
           
            if(res.status === APP_CONST.RESPONSE_200) {
                this.apiResponseCode = APP_CONST.RESPONSE_200;
                this.createUser = { ...APP_CONST.USER_CREATION };
                this.duplicateUser = APP_CONST.FALSE;
                this.isApiError=APP_CONST.FALSE;
                this.allFieldRequired = APP_CONST.FALSE;
                this.updateParentList({status: true, roleId: createUser.userRoles[0].roleId, update: true});
            }
            if(res.status === APP_CONST.RESPONSE_406) {
                this.apiResponseCode = APP_CONST.RESPONSE_400;
                this.duplicateUser = APP_CONST.TRUE;
            }
             if(res.status === APP_CONST.RESPONSE_400) {
                this.apiResponseCode = APP_CONST.RESPONSE_400;
                this.isApiError=APP_CONST.TRUE;
                this.apiErrorMessage=res.data;
             }
        })
    }

    updateUser(updateUser: ICreateUser) {
        if (updateUser.inactiveDate) {
            let inactiveDate = updateUser.inactiveDate.toString();
            inactiveDate = APP_UTILITIES.formatShortDate(inactiveDate)
            if(updateUser.status != APP_CONST.USER_STATUS_INACTIVE && inactiveDate == this.todayDate){
                updateUser.inactiveDate = APP_CONST.BLANK;
            }
        }
        this.isValid = false;
        this.apiResponseCode = 0;
        editUser(updateUser).then((res: any) => {
             
            if(res.status === APP_CONST.RESPONSE_200) {
                this.apiResponseCode = APP_CONST.RESPONSE_200;
                this.duplicateUser = APP_CONST.FALSE;
                this.updateParentList({status: true, roleId: updateUser.userRoles[0].roleId, update: false});
            }
            if(res.status === APP_CONST.RESPONSE_406) {
                this.apiResponseCode = APP_CONST.RESPONSE_400;
                this.duplicateUser = APP_CONST.TRUE;
            }
            if(res.status === APP_CONST.RESPONSE_400) {
                this.apiResponseCode = APP_CONST.RESPONSE_400;
                this.isApiError=APP_CONST.TRUE;
                this.apiErrorMessage=res.data;
            }
            
        })
    }

    refillForm() {
        this.createUser = JSON.parse(JSON.stringify(APP_CONST.USER_CREATION)) as ICreateUser;
        this.validatedFields = JSON.parse(JSON.stringify(APP_CONST.VALIDATE_CREATE_USER_FORM));
        this.isValidEmail = null;
        this.duplicateUser = false;
        this.errorMsg = '';
        this.isApiError=APP_CONST.FALSE;
        this.apiErrorHandling();
    }

    back() {
        this.allFieldRequired = false;
        this.isPhoneLengthValid = null
        this.isApiError=APP_CONST.FALSE;
        this.refillForm();
        this.isValid = true;
        this.apiResponseCode = 0;
        this.$emit(APP_CONST.BACK_BUTTON,{ id: 'back', user: this.user });
        this.scrollReset();
    }

    close() {
        this.disableStatus=false;
        this.openDropStatus = false;
        this.openDropUserStatus=false;
        this.allFieldRequired = false;
        this.isPhoneLengthValid = null
        this.isApiError=APP_CONST.FALSE;
        this.refillForm();
        this.isValid = true;
        this.apiResponseCode = 0;
        this.$emit(APP_CONST.CLOSE, 'add-update-user')
        this.scrollReset();
    }
    
    scrollReset()
    {
        let elem:any  = document.getElementById('edit-user') as HTMLElement ;
        elem.scrollTo(0,0);
    }

    CheckPrimeAcc()
    {
        if(this.createUser.userRoles[0].isPrimaryAccountAdmin)
        {
            this.createUser.userRoles[0].isPrimaryAccountAdmin=false;
        }
        else
        {
            this.createUser.userRoles[0].isPrimaryAccountAdmin=true;
        }
    }

   
}